.carrito-container {
  padding: 20px;
}

.carrito-lista {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  max-width: 666px;
  margin: 40px auto;
}

.carrito-item {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.carrito-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.imagen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80px; /* Ancho máximo para la imagen */
}

.carrito-item img.carrito-imagen {
  width: 100%;
}

.carrito-item p {
  margin: 5px;
}

.carrito-item p.nombre-item {
  width: 100%; /* Usar todo el ancho del contenedor */
  text-align: left; /* Centrar el nombre del producto */
}

.carrito-item p.precio,
.carrito-item p.total,
.carrito-item p.cantidad {
  text-align: center; /* Alineación centrada */
}

.cantidad-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carrito-item .btn-menos,
.carrito-item .btn-mas {
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  border: 2px solid rgba(183, 249, 0, 0.7);
  width: 33px;
  height: 40px;
  margin: 0 5px;
}

.carrito-item .btn-menos:hover,
.carrito-item .btn-mas:hover {
  background-color: rgba(183, 249, 0, 0.7);
}

.carrito-item .btn-eliminar {
  background: url('../../public/img/eliminar.png') no-repeat center center;
  background-size: contain;
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.carrito-item .btn-eliminar:hover {
  background: url('../../public/img/eliminar-hover.png') no-repeat center center;
  background-size: contain;
}

.forma-pago {
  margin: 20px 0;
}

.btn {
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
}

.btn-pagar {
  background-color: #007bff;
  color: white;
  border: none;
}

.btn-pagar:hover {
  background-color: #0056b3;
}

.cantidad-item {
  padding: 0 5px;
}

.btn-mas,
.btn-menos {
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  border: 2px solid rgba(183, 249, 0, 0.7);
  width: 33px;
  height: 40px;
}

.btn-mas:hover,
.btn-menos:hover {
  background-color: rgba(183, 249, 0, 0.7);
}

/* Estilos específicos para el botón eliminar */
.btn-eliminar {
  background: url('../../public/img/eliminar.png') no-repeat center center;
  background-size: contain;
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.btn-eliminar:hover {
  background: url('../../public/img/eliminar-hover.png') no-repeat center center;
  background-size: contain;
}

.datos-bancarios {
  margin-top: 20px;
  max-width: 400px;
  margin: 0 auto;
}

.datos-bancarios table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
}

.datos-bancarios td {
  padding: 8px;
  border: 1px solid rgba(183, 249, 0, 0.1);
}

.datos-bancarios th {
  padding: 8px;
  border: 1px solid rgba(183, 249, 0, 0.1);
  text-align: left;
}

.datos-bancarios th, .datos-bancarios td {
  padding: 8px;
}

.datos-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-copiar {
  padding: 1px 10px;
  color: rgba(183, 249, 0, 0.5);
  border: none;
  cursor: pointer;
  border-radius: 6px;
  font-size: 12px;
}

.btn-copiar span {
  vertical-align: middle; /* Alinea el ícono verticalmente con el texto */
}



.datos-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}


