.product-move-card-wrapper {
  perspective: 1000px;
  transition: transform 0.3s ease;
}

.product-move-card-wrapper.hovered {
  transform: scale(1.05);
}

.product-move-card {
  width: 150px;
  height: auto;
  position: relative;
  margin: 0 auto;
}

.product-move-flip-container {
  position: relative;
  width: 100%;
  height: auto; /* Asegura que el contenedor se ajuste a su contenido */
  overflow: hidden;
}

.product-move-img {
  transition: transform 0.5s ease-in-out;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajusta las imágenes para que cubran el área sin distorsionarse */
  max-height: 100px; /* Limita la altura de las imágenes */
}

.product-move-card-body {
  padding: 1rem;
  text-align: center;
}

.product-move-card-title {
  font-size: 1.25rem; /* Ajusta el tamaño de la fuente */
  margin-bottom: 0.5rem;
}

.product-move-card-actions {
  display: flex;
  justify-content: center;
}

.product-move-carousel {
  overflow-x: auto; /* Permitir scroll horizontal */
  overflow-y: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 1rem 0; /* Añade espacio alrededor del carrusel */
}

.product-move-track {
  display: flex;
  width: calc(150px * 22); /* Ajusta según el número de productos duplicados */
  animation: scroll 60s linear infinite;
}

.product-move-carousel.paused .product-move-track {
  animation-play-state: paused;
}

.product-move-carousel-item {
  flex: 0 0 auto;
  width: 150px;
  transition: transform 0.5s ease;
  margin-right: 5px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-150px * 11)); /* Ajusta según el número de productos */
  }
}
