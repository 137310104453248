.input-container {
  align-items: center;
  display: flex;
  gap: 5px;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
}

.input-field {
  box-sizing: border-box;
  max-width: 450px;
  padding: 8px;
  width: 100%;
}

.btn-copiar {
  align-items: center;
  display: flex;
  padding: 8px;
}
