:root {
  --color-primary: #B7F900;
  --color-opuesto: #8F00B7;
  --color-background: #1d232a;
  --color-background-header: #15191e;
  --color-background-transparente: #15191e78;
  --color-text: #a6adbb;
  --color-transparencia: rgba(183, 249, 0, 0.5);
  --color-cancelar: #dc5580;
}

@font-face {
  font-family: 'MatiMarket';
  src: url('./fonts/MatiMarket.woff') format('opentype');
  font-weight: normal;
  font-style: normal;
}


.background-glass{
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  @supports not (backdrop-filter: blur(10px)) {
    background: rgba(255, 255, 255, 0.8);
  }
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 20px;
  width: -moz-fit-content; 
  width: fit-content;
  margin: auto;
  z-index: 2000 !important;
}

.bg-header, .modal-box{
  background-color: var(--color-background) !important;
}
.bg-transparencia{
  background-color: var(--color-transparencia) !important;
}

.modal-box{
  border-radius: 0 !important;
  max-width: 466px !important;
  transform: translateY(-50%) !important;
}



select,input{
  background-color: var(--color-background-header) !important;

}

.border-header{
  border: 1px solid var(--color-background-header) !important;
}

.fonts-mm {
  font-family: 'MatiMarket', sans-serif;
}

.title-mm {
  font-family: 'MatiMarket', sans-serif;
  color: var(--color-primary);
  font-size: 3em !important;
  letter-spacing: 0.1em;
  margin: 50px 0;
  width: 100%;
}

.text-success-mm {
  color: var(--color-primary);
}

.subtitle-mm {
  font-family: 'MatiMarket', sans-serif;
  color: var(--color-primary);
  font-size: 1.3em;
  letter-spacing: 0.1em;
  margin: 0 12px;
}

.color-mm {
  color: var(--color-primary) !important;
}

.background-mm {
  background-color: var(--color-primary) !important;
}

@keyframes borderGradient {
  0% {
    border-image: linear-gradient(to right, var(--color-primary), var(--color-text)) 1;
  }
  50% {
    border-image: linear-gradient(to right, var(--color-text), var(--color-primary)) 1;
  }
  100% {
    border-image: linear-gradient(to right, var(--color-primary), var(--color-text)) 1;
  }
}

@keyframes movingLight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.border-mm {
  position: relative !important;
  border-image: linear-gradient(to right, var(--color-primary), var(--color-text)) 1 !important;
  animation: borderGradient 5s infinite !important;
  overflow: hidden !important;
}

.border-mm::before {
  content: '' !important;
  position: absolute !important;
  top: 0; bottom: 0; left: 0; right: 0 !important;
  z-index: -10 !important;
}

.border-mm::after {
  content: '' !important;
  position: absolute !important;
  top: 0; bottom: 0; left: -50%; right: -50% !important;
  background: linear-gradient(90deg, transparent, rgba(177, 255, 31, 0.2), transparent) !important;
  z-index: -20 !important;
  animation: movingLight 3s infinite !important;
}

.enlace-mm{
  color: var(--color-primary) !important;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.enlace-mm:hover{
  color: var(--color-transparencia) !important;
  text-decoration: underline !important;
}


.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



.btn-ghost-mm {
  border: 2px solid var(--color-primary);
  background-color: var(--color-background-transparente) !important;
  color: var(--color-primary);
  font-family: 'MatiMarket', sans-serif;
  font-size: 1.6em;
  letter-spacing: 0.1em; /* Aumenta este valor para más espacio */
  transition: transform 0.5s ease, border-color 0.5s ease, color 0.5s ease, background-color 0.5s ease;
  padding: 20px 40px;
  text-align: center !important;
  display: block !important;
}

.btn-ghost-mm:hover {
  background-color: var(--color-primary) !important;
  border: 3px solid rgba(0, 0, 0, 0.6);
  color: rgba(0, 0, 0, 0.6);
  font-size: 1.5em;
  letter-spacing: 0.2em; /* Aumenta este valor para más espacio */
}

.badge-mm {
  /* background-color: white !important; */
  border: 2px solid var(--color-transparencia) !important;
  color: var(--color-primary) !important;
  font-weight: 500 !important;
}

.text-mm{
  color: var(--color-primary);
}


.btn-primary {
  color: var(--color-background-header) !important;
  background-color: #77A200 !important;
  border: 2px solid #527001 !important;
}

.btn-accent {
  color: var(--color-primary) !important;
  background-color: var(--color-background) !important;
  border: 3px solid hsl(76, 98%, 22%) !important;
}

.btn-secondary{
  color: var(--color-background-header) !important;
  background-color: var(--color-text) !important;
  border: 3px solid var(--color-primary) !important;
}

.btn-disabled{
  color: #702f01 !important;
  background-color: var(--color-background) !important;
  border: 1px solid #702f01 !important;
}

.background-mm, body, html {
  background-color: var(--color-background) !important;
  color: var(--color-text) !important;
}

.background-header {
  background-color: var(--color-background-header) !important;
  color: var(--color-text) !important;
}


.progress-container{
  position: relative;
  width: 100%;

}

.progress{
  background-color: rgba(102, 121, 64, 0.3) !important;
}


.progress::-webkit-progress-value {
  background-color: var(--color-primary) !important;
}

.progress::-moz-progress-bar {
  background-color: var(--color-primary) !important;
}

.progress::-ms-fill {
  background-color: var(--color-primary) !important;
}

.container-generico {
  max-width: 100%;
  margin: auto; /* Center the container */
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Table styling */
table {
  width: 100%;
  border-collapse: collapse; /* Ensures that borders between cells are merged */
  margin: 20px 0;
}

th, td {
  border: 1px solid #263401 !important; /* Light border for the table cells */
  padding: 12px 15px; /* Padding inside cells */
  text-align: left; /* Align text to the left */
  word-wrap: break-word; /* Allows long words to break and fit into cells */
}

th {
  background-color: var(--color-background-header); /* Light background for header cells */
  font-weight: bold;
}

tbody tr:nth-child(even) {
  background-color: var(--color-background-header); /* Zebra stripes for even rows */
}

tbody tr:hover {
  background-color: var(--color-transparencia); /* Highlight row on hover */
  color: var(--color-background-header);
}


.product-image{
  width: 90px;
  height: 60px;
}


.checkmark {
  width: 96px;
  height: 96px;
  animation: draw 2s ease-in-out forwards;
  text-align: center;
  margin: 0 auto;
}

@keyframes draw {
  0% {
    stroke-dasharray: 0 480;
    stroke-dashoffset: 240;
  }
  100% {
    stroke-dasharray: 240 480;
    stroke-dashoffset: 0;
    fill: var(--color-primary);
  }
}

.checkmark path {
  fill: none;
  stroke: var(--color-primary);
  stroke-width: 10;
  transition: fill 0.5s ease-in-out;
}

.checkmark.filled path {
  fill: var(--color-primary);
  stroke: none;
}

.filled {
  fill: var(--color-primary);
}

.loading-spinner {
  display: block;
  margin: 0 auto;
  margin-top: 20px; /* Ajusta el valor según sea necesario */
}

/* Animaciones */
.animate-fade-in {
  animation: fadeIn 1s ease-out;
}

.animate-slide-in-left {
  animation: slideInLeft 1s ease-out;
}

.animate-slide-in-right {
  animation: slideInRight 1s ease-out;
}

.animate-fade-in-up {
  animation: fadeInUp 1s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideInLeft {
  from { transform: translateX(-100px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes slideInRight {
  from { transform: translateX(100px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes fadeInUp {
  from { transform: translateY(50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

/* Estilos responsivos para tablas */
@media screen and (max-width: 600px) {
  .tabla-responsive {
    border: 0;
  }

  .tabla-responsive caption {
    font-size: 1.3em;
  }
  
  .tabla-responsive thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .tabla-responsive tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 2em;
  }
  
  .tabla-responsive td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  .tabla-responsive td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .tabla-responsive td:last-child {
    border-bottom: 0;
  }
}

/* Estilos específicos para la tabla de Mis Puntos */
@media screen and (max-width: 600px) {
  .tabla-mis-puntos td:first-child {
    text-align: center;
  }

  .tabla-mis-puntos .product-image {
    max-width: 100%;
    height: auto;
  }
}