.productos-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.filter-sort-container {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.input-container {
  width: 100%;
}

.filter-input {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
}

.select-container {
  display: flex;
  justify-content: space-between;
}

.sort-select,
.category-select {
  width: 48%;
  padding: 0.5rem;
  font-size: 1rem;
}

.productos-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.producto-card {
  width: 250px;
  max-width: 250px;
  height: 380px;
  max-height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.producto-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.producto-imagen {
  width: 100%;
  height: auto;
}

.card-body {
  padding: var(--padding-card, 1rem) !important;
  flex: 1;
}

.card-title {
  display: block;
  font-size: 1em !important;
}

.producto-info-container {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  margin-bottom: 14px;
}

.categoria {
  text-align: center;
  margin-bottom: 0.5rem;
}

.producto-precio-seccion,
.producto-puntos-seccion {
  width: 48%;
  text-align: center;
}

.producto-precio-seccion span,
.producto-puntos-seccion span {
  display: block;
  font-weight: bold;
}

.btn-container {
  position: absolute;
  bottom: 30px !important;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  width: calc(100% - 20px);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
}

.producto-card:hover .btn-container {
  opacity: 1;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease;
}

.checkbox-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0.5rem;
  cursor: pointer;
}

.checkbox-image {
  width: 90%;
  height: 90%;
  border-radius: 50%;
}

.indicator {
  z-index: 1000;
}

@media (max-width: 768px) {
  .producto-card {
    width: 180px; 
    max-width: 180px;
    height: 310px;
    max-height: 310px;
  }
  .card-title {
    display: block;
    font-size: 0.7em !important;
  }
  .badge {
    font-size: 0.7em !important;
  }
  .producto-link .btn {
    font-size: 0.8em !important;
    padding: 10px 8px;
  }

  .checkbox-custom {
    align-self: center;
    margin-top: 1rem;
  }
}