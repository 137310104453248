.alert-mm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background-color: var(--color-background) !important;
  color: rgb(199, 196, 202);
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1000;
  animation: slideDown 0.5s ease-out, bounce 0.5s ease-in-out;
  transition: top 0.5s, transform 0.5s; 
}

@keyframes slideDown {
  0% {
    top: -100px;
  }
  100% {
    top: 10%;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translate(-50%, 0);
  }
  50% {
    transform: translate(-50%, 10%);
  }
}

@keyframes slideUp {
  0% {
    top: 10%;
  }
  100% {
    top: -100px;
  }
}

@keyframes bounceUp {
  0%, 100% {
    transform: translate(-50%, 0);
  }
  50% {
    transform: translate(-50%, -10%);
  }
}

.close-button {
  background: none;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.close-button svg {
  width: 24px;
  height: 24px;
  stroke: rgb(149, 255, 0);
}

.card {
  margin-top: 1rem;
}

.card-actions {
  display: flex;
  /* justify-content: space-between;  */
  width: 100%; 
}
.closing {
  animation: slideUp 0.5s ease-out, bounceUp 0.5s ease-in-out;
}