.my-account {
  max-width: 333px;
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: center;
}

form {
  display: block; /* Change display to block or grid */
  width: 100%;
  margin-top: 20px; /* Space above the form */
}

form > div {
  margin-bottom: 10px; /* Space between rows */
}

label, input {
  display: block; /* Display labels and inputs as block elements */
  /* margin-bottom: 10px; */
}

label {
  font-weight: bold;
  margin: 0 !important;
}

input {
  width: 100%;
  box-sizing: border-box;
  padding: 8px !important;
  border-radius: 4px;
  margin-bottom: 10px !important;
  text-align: center;
}

button {
  display: block; /* Ensure the button is treated as a block element */
  margin: 20px auto; /* Center the button horizontally */
}

.input-container {
  display: flex;
  justify-content: center; /* Center content horizontally */
  margin-top: 20px; /* Space above the button */
}

input[type="date"] {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
}
