.como-participar-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2.5em;
  color: var(--color-primary);
}

.section {
  margin-bottom: 40px;
}

h2 {
  font-size: 1.75em;
  color: var(--color-secondary);
  margin-bottom: 10px;
}

p {
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 10px;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}

li {
  margin-bottom: 5px;
  font-size: 1.1em;
}

.btn-primary {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 1.2em;
  color: #fff;
  background-color: var(--color-primary);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: var(--color-primary-dark);
}


.container-buttons {
  display: flex;
  justify-content: center; /* Para centrar horizontalmente */
  gap: 20px; /* Espacio entre los botones */
  margin-top: 20px; /* Espacio superior para separar de otros elementos */
}

.links {
  text-decoration: none; /* Para eliminar el subrayado de los links */
}