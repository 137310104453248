.participa-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.imagen-grande {
  margin-bottom: 20px;
}

.imagen-principal {
  max-width: 400px;
  max-height: 300px;
  height: auto;
}

.imagenes-pequenas {
  display: flex;
  justify-content: center;
}

.imagen-pequena {
  width: 60px;
  height: 40px;
  margin: 0 5px;
  cursor: pointer;
}

.info-producto {
  text-align: center;
  margin-top: 20px;
}

.tickets-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.card-ticket {
  position: relative;
  flex: 1 1 calc(33.33% - 20px);
  box-sizing: border-box;
  margin: 10px;
  transition: border 0.3s ease-in-out;
}

.card-ticket:hover .card-title {
  font-size: 1.3em !important;
  letter-spacing: 3px;
  transition: font-size 0.5s ease-in-out;
  transition: letter-spacing 0.3s ease-in-out;
}

.card-ticket:hover .buy-button {
  bottom: 50%;
  opacity: 1;
}

.abonar-puntos-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.puntos-input {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.flecha-svg {
  font-family: 'MatiMarket';
  color: #79a208 !important;
  font-size: 3em;
  cursor: pointer;
  padding: 0 10px;
  -webkit-user-select: none;
  user-select: none;
}

.abonar-puntos-container input {
  padding: 10px;
  font-size: 1em;
  text-align: center;
  width: 60px;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.abonar-puntos-container input::-webkit-outer-spin-button,
.abonar-puntos-container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.abonar-puntos-container button {
  padding: 10px 20px;
  font-size: 1em;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

.abonar-puntos-container button:hover {
  background-color: #0056b3;
}

.buy-button {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: #B7F900;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: bottom 0.5s ease, opacity 0.5s ease;
  opacity: 0;
  border-radius: 12px;
}

.buy-button:hover {

  background-color: #282c34;
}





@media (max-width: 1023px) {
  .card-ticket {
    flex: 1 1 calc(50% - 20px);
  }
}

@media (max-width: 767px) {
  .card-ticket {
    flex: 1 1 100%;
  }
}