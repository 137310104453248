@keyframes moveShine {
    0% {
      transform: translate(-100%, -100%);
      opacity: 0; /* Ajustamos la opacidad al principio para que aparezca gradualmente */
    }
    100% {
      transform: translate(100%, 100%);
      opacity: 1; /* Ajustamos la opacidad al final para que desaparezca gradualmente */
    }
  }
  
  .shine-overlay {
    
    animation: moveShine 0.5s linear forwards;
  }

  .card-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
  