.comprar-tickets-container {
  padding: 20px;
}

.title-mm {
  font-size: 2rem;
  margin-bottom: 20px;
}

.tickets-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.card-ticket {
  position: relative;
  flex: 1 1 calc(33.33% - 20px);
  box-sizing: border-box;
  margin: 10px;
  transition: border 0.3s ease-in-out;
}

.card-ticket:hover .card-title {
  font-size: 1.3em !important;
  letter-spacing: 3px;
  transition: font-size 0.5s ease-in-out;
  transition: letter-spacing 0.3s ease-in-out;
}

.card-ticket:hover .buy-button {
  bottom: 50%;
  opacity: 1;
}

.buy-button {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: #B7F900;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: bottom 0.5s ease, opacity 0.5s ease;
  opacity: 0;
  border-radius: 12px;
}

.buy-button:hover {
  background-color: #282c34;
}

@media (max-width: 1023px) {
  .card-ticket {
    flex: 1 1 calc(50% - 20px);
  }
}

@media (max-width: 767px) {
  .card-ticket {
    flex: 1 1 100%;
  }
}
