.comprar-producto-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.imagen-grande {
  margin-bottom: 20px;
}

.imagen-principal {
  max-width: 400px;
  max-height: 300px;
  height: auto;
}

.imagenes-pequenas {
  display: flex;
  justify-content: center;
}

.imagen-pequena {
  width: 60px; /* Tamaño cuadrado */
  height: 40px; /* Tamaño cuadrado */
  margin: 0 5px; /* Espacio entre las imágenes */
  cursor: pointer;
}

.info-producto {
  text-align: center;
  margin-top: 20px; /* Espacio entre las imágenes pequeñas y la información del producto */
  width: 100%; /* Asegura que ocupe todo el ancho disponible */
  max-width: 800px; /* Limita el ancho máximo para mejor legibilidad */
}

.producto-descripcion {
  margin-top: 20px;
  padding: 15px;
  background-color: var(--color-background-header);
  border-radius: 5px;
}
.titulo-descripcion {
  margin-bottom: 40px !important;
  margin-top: 40px !important;
}
.producto-descripcion .descripcion-contenido {
  text-align: left;
}

.producto-descripcion .descripcion-contenido h3,
.producto-descripcion .descripcion-contenido ul,
.producto-descripcion .descripcion-contenido p {
  text-align: left;
}

.producto-descripcion .descripcion-contenido ul {
  padding-left: 20px;
}

.producto-descripcion h2 {
  font-size: 1.4em;
  margin-bottom: 15px;
  /* color: #333; */
}

.seccion-descripcion {
  margin-bottom: 20px;
}

.seccion-descripcion h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
  /* color: #444; */
}

.producto-descripcion ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 15px;
}

.producto-descripcion li {
  margin-bottom: 5px;
}

.producto-titulo,
.producto-precio,
.cantidad-container,
.btn-comprar {
  align-self: flex-start;
  width: 100%;
}

.imagen-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.btn-comprar {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

.btn-comprar:hover {
  background-color: #0056b3;
}

.cantidad-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px; /* Espacio entre las imágenes pequeñas y la información del producto */
}

.cantidad-input {
  width: 50px;
  text-align: center;
  margin: 0 9px !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  /* margin-top: 11px !important; */
}

.btn-mas,
.btn-menos {
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    border: 2px solid rgba(183, 249, 0, 0.7);
    width: 33px;
    height: 40px;
}

.btn-mas:hover,
.btn-menos:hover {
  background-color: rgba(183, 249, 0, 0.7);
}