.hover-underline {
  position: relative;
  color: inherit;
  text-decoration: none;
}

.hover-underline::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin: auto;
  left: 0;
  right: 0;
  bottom: -5px;
  background: var(--color-primary);
  transition: width 0.3s ease-in-out;
}

.hover-underline:hover::after {
  width: 100%;
}

.drawer-side {
  z-index: 50;
}

.drawer-underline {
  position: relative;
  color: inherit;
  text-decoration: none;
}

.drawer-underline::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  background: var(--color-primary);
  width: 0;
  transition: width 0.3s ease-in-out;
}

.drawer-underline-active::after,
.active-mobile::after {
  width: 100%;
}

.active-mobile {
  color: var(--color-primary);
}

.active {
  color: var(--color-primary);
}

.active::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  display: block;
  margin: auto;
  left: 0;
  right: 0;
  bottom: -5px;
  background: var(--color-primary);
  transform: scaleX(1);
  transition: transform 0.3s ease-in-out;
}

.activo-dropdown {
  position: relative;
  color: var(--color-primary) !important;
}

.activo-dropdown::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  background: var(--color-primary);
  width: 100%;
}

.active-animation {
  animation: activeSlide 0.5s ease-in-out;
}

@keyframes activeSlide {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

.mr-30 {
  margin-right: 30px;
}

.logo {
  height: 45px;
}

.btn-settings {
  max-width: 26px;
  transition: transform 0.6s ease-in-out;
}

.btn-settings:hover {
  transform: rotate(66deg);
}

.points-container {
  position: fixed;
  top: -50px; /* Inicia fuera de la vista */
  right: 40px;
  display: flex;
  align-items: center;
  background-color: var(--color-background-header);
  padding: 5px 10px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: 1;
  opacity: 0; /* Inicialmente invisible */
  transition: top 1.5s ease-in-out, opacity 1.5s ease-in-out; /* Transición suave */
}

.points-container.visible {
  top: 72px; /* Posición visible */
  opacity: 1; /* Opacidad completa */
}

.points-container img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.points-display {
  font-weight: bold;
}

.menu {
  width: 220px !important;
  margin-top: 64px;
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
}

.menu li {
  background-color: none !important;
}

.menu li > *:not(ul, .menu-title, details, .btn):active,
.menu li > *:not(ul, .menu-title, details, .btn).active,
.menu li > details > summary:active {
  background-color: transparent !important; /* Remove background color change */
}

.menu-logout {
  margin-top: auto;
}

.dropdown-content{
  top: -20px !important;
}

