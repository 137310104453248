.el-montoncito {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.area-juego {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  padding: 1rem;
  height: auto;
  align-items: center;
  user-select: none;
}

.monton {
  width: 150px;  /* o el tamaño que prefieras */
  height: 225px; /* manteniendo la proporción 2:3 */
  position: relative;
  aspect-ratio: 3 / 4;
  max-width: 200px;
  margin: 0 auto;
  transition: all 0.3s ease-in-out, transform 0.6s, border-color 0.3s ease; /* Transición del borde */
  pointer-events: auto;
  cursor: pointer;
  outline: none;
  margin-top: 30px;
  border: 2px solid transparent; /* Borde transparente inicialmente */
  border-radius: 10px;
}

.monton > * {
  pointer-events: none;
}

.monton button,
.monton .botones-apuesta {
  pointer-events: auto;
  cursor: pointer;
  outline: none;
}

.monton.elevado {
  transform: scale(1.01);
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.monton img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.monton.ganada {
  border-color: green; /* Cambia el color del borde a verde para ganada */
  border-width: 2px; /* Ajusta el grosor del borde */
  border-style: solid; /* Estilo de borde sólido */
  box-shadow: 0 0 5px green; /* Sombra difusa para suavizar el borde */
}

.monton.perdida {
  border-color: red; /* Cambia el color del borde a rojo para perdida */
  border-width: 2px; /* Ajusta el grosor del borde */
  border-style: solid; /* Estilo de borde sólido */
  box-shadow: 0 0 5px red; /* Sombra difusa para suavizar el borde */
}

.monton.banca {
  border-color: gold; /* Cambia el color del borde a dorado para banca */
  border-width: 2px; /* Ajusta el grosor del borde */
  border-style: solid; /* Estilo de borde sólido */
  box-shadow: 0 0 5px gold; /* Sombra difusa para suavizar el borde */
}

.monton.revelado {
  opacity: 1; /* Asegúrate de que el borde sea visible */
}

/* Agrega esto a tu archivo CSS */
.borde-visible {
  position: relative;
}

.borde-visible::before,
.borde-visible::after {
  content: '';
  position: absolute;
  border: 4px solid transparent; /* Ajusta el grosor del borde */
  z-index: 1;
  transition: all 0.5s ease;
}

.borde-visible::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-color: currentColor; /* Usa el color del resultado */
  animation: completarBorde 1s forwards; /* Aplica la animación */
}

.borde-visible::after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-color: currentColor; /* Usa el color del resultado */
  animation: completarBorde 1s forwards;
  animation-delay: 0.5s; /* Retrasa la segunda parte */
}

@keyframes completarBorde {
  0% {
    width: 0;
    height: 0;
  }
  100% {
    width: 100%;
    height: 100%;
  }
}

@keyframes pulso {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.monton:hover {
  transform: scale(1.1);
}

.total-apostado {
  font-size: 0.6em;
  left: 0;
  position: absolute;
  text-align: center;
  top: 65% !important;
  width: 100%;
}

.monto-total-apostado{
  font-weight: bold;
  font-size: 1.2em;
  color: var(--color-primary);
}

.botones-apuesta {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 5px;
  box-sizing: border-box;
}

.apuesta-minima, .apuesta-maxima {
  background-color: var(--color-transparencia);
  border-radius: 3px;
  border: 1px solid var(--color-primary);
  color: var(--color-background-header);
  cursor: pointer;
  font-size: 0.6em;
  padding: 1px 2px;
  width: 30%; /* Ajusta este valor según necesites */
  max-width: 60px;
  margin: 0 5px;
  outline: none;
}

.retirar-apuesta {
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0; /* Inicialmente oculto */
  transform: translateY(10px); /* Desplazado hacia abajo */
  position: absolute !important;
  top: 0;
  display: block; 
  left: 50% !important;
  height: 30px;
  transform: translateX(-50%) !important;
  padding: 2px 5px !important ;
  font-size: 0.8em;
  background-color: var(--color-background-header);
  border: 1px solid var(--color-cancelar);
  color: var(--color-cancelar);
  border-radius: 3px;
  cursor: pointer;
  outline: none;
}

.retirar-apuesta.visible {
  opacity: 1; /* Visible */
  transform: translateY(0); /* Regresa a su posición original */
}

.apuesta-actual {
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0; /* Inicialmente oculto */
  transform: translateY(10px); /* Desplazado hacia abajo */
  position: absolute;
  display: block;
  top: -20px;
  left: 50% !important;
  transform: translateX(-50%) !important;
  text-align: center;
  font-size: 0.8em;
  font-weight: bold;
  color: gold;
  text-shadow: 1px 1px 1px black;
  width: 80px;
  height: 30px;
  bottom:0;
  right:0;
}

.apuesta-actual.visible {
  opacity: 1; /* Visible */
  transform: translateY(0); /* Regresa a su posición original */
}

.controles-apuesta {
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.contador {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(240, 240, 240, 0.8);
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 10;
}

.contador p {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  color: #2c3e50;
}

@media (max-width: 1200px) {
  .area-juego {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .area-juego {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .area-juego {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .monton {
    min-width: unset;
  }
}

@media (min-width: 768px) {
  .botones-apuesta {
    padding: 10px;
  }

  .apuesta-minima, .apuesta-maxima {
    font-size: 0.7em;
    padding: 2px 4px;
  }
}

.resultado-apuesta {
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

.carta-contenedor {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.carta-frente,
.carta-reverso {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carta-reverso {
  transform: rotateY(180deg);
}

.resultado-apuesta {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 5px;
  color: white;
  font-weight: bold;
  backface-visibility: hidden;
}

.resultado-apuesta.ganada {
  background-color: rgba(0, 128, 0, 0.7) !important;
}

.resultado-apuesta.perdida {
  background-color: rgba(255, 0, 0, 0.7) !important;
}

.resultado-apuesta.banca {
  background-color: rgba(255, 215, 0, 0.7) !important;
  color: black;
}

.resultados {
  position: fixed; /* Fijar en la pantalla */
  top: 0; /* Alinear al inicio de la pantalla */
  left: 0; /* Alinear a la izquierda */
  width: 100%; /* Ancho completo */
  height: 100%; /* Alto completo */
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
  z-index: 100; /* Asegurarse de que esté por encima de otros elementos */
  display: flex; /* Usar flexbox para centrar contenido */
  align-items: center; /* Centrar verticalmente */
  justify-content: center; /* Centrar horizontalmente */
}

/* Asegúrate de que el contenedor esté centrado */
.relative {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centrar horizontalmente */
  justify-content: center; /* Centrar verticalmente */
}